<template>
  <div class="checkboxWrapper">
    <!-- basic -->
    <validation-observer ref="userEmailValidation">
      <b-form-group :label="labelText" label-for="emailInput">
        <validation-provider
          #default="{ errors }"
          name="Email"
          rules="required|email"
        >
          <div class="inputAndButtonWrapper">
            <b-form-input
              v-model="userEmail"
              name="email"
              placeholder="Enter Email"
            />
            <b-button
              variant="primary"
              class="getRolesButton ml-1 mr-1"
              @click.prevent="getUserRoles"
            >
              Get Roles
            </b-button>
          </div>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider></b-form-group
      >
    </validation-observer>
    <!-- <div v-if="userEmail && !userRoles" class="d-flex justify-content-center">
      <span>{{ `User with email: ${userEmail} doesn't exist.` }}</span>
    </div> -->
    <b-form-group :label="chooseLabelText">
      <b-form-checkbox-group
        v-if="userRoles"
        id="checkbox-group-1"
        v-model="selected"
        :options="userRoles"
        disabled-field="notEnabled"
        name="flavour-1"
        class="demo-inline-spacing"
      />
    </b-form-group>
    <b-button
      v-if="userRoles"
      variant="primary"
      class="mt-2 mr-1 mb-2"
      @click.prevent="validationForm"
    >
      Save changes
    </b-button>
    <b-table
      responsive="sm"
      :items="admins"
      hover
      @row-clicked="(e) => onRowClick(e)"
    />
  </div>
</template>

<script>
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormGroup,
  BButton,
  BFormInput,
  BTable,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import roles from "./roles.json";

export default {
  components: {
    BFormGroup,
    BButton,
    BTable,
    BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
  },
  data() {
    return {
      required,
      email,
      userEmail: "",
      userId: null,
      selected: [],
      options: roles,
    };
  },
  computed: {
    ...mapState("roleManagement", ["userRoles", "admins"]),
    labelText() {
      return `Choose roles for admin with email: ${this.userEmail}`;
    },
    chooseLabelText() {
      if (this.userRoles) {
        return `Choose roles then save changes`;
      }
      return "";
    },
  },
  watch: {
    userRoles(val) {
      this.selected = val
        .filter((res) => res.isEnabled)
        .map((ele) => ele.value);
    },
  },
  created() {
    this.getAdmins();
  },
  beforeDestroy() {
    this.$store.commit("roleManagement/CLEAR_USER_ROLES");
  },
  methods: {
    ...mapActions("roleManagement", [
      "setUserRole",
      "getUserRole",
      "getAdmins",
    ]),
    getUserRoles() {
      if (this.userEmail) {
        this.getUserRole({ email: this.userEmail, id: this.userId });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Choose user to get roles",
            icon: "BellIcon",
            variant: "danger",
          },
        });
      }
    },
    onRowClick(e) {
      // this.copy(e.email);
      this.userEmail = e.email;
      this.userId = e.id;
      this.getUserRoles();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `${e.email} copied!`,
          icon: "CopyIcon",
          variant: "success",
        },
      });
    },
    async copy(s) {
      await navigator.clipboard.writeText(s);
    },
    validationForm() {
      this.$refs.userEmailValidation.validate().then((success) => {
        if (success) {
          this.setUserRole({
            id: this.userId,
            roles: this.userRoles.map((x) => ({
              id: x.roleId,
              isEnabled: this.selected.includes(x.value),
            })),
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.checkboxWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.inputAndButtonWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  @media (max-width: 600px) {
    flex-direction: column;
    height: unset;
  }
}
.getRolesButton {
  width: 20%;
  @media (max-width: 600px) {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 15px;
  }
}
.inputAndButtonWrapper input {
  width: 70%;
  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
  height: 100%;
}
</style>
